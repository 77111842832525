export const dataITEConecta = [
  {
    "id": 1,
    "fecha": "2023-03-02",
    "title": "ITE Conecta Programa 1, ¿A las y los jóvenes les interesa lo público?"
  },
  {
    "id": 2,
    "fecha": "2023-03-09",
    "title": "ITE Conecta Programa 2, Activismo feminista de las jóvenes"
  },
  {
    "id": 3,
    "fecha": "2023-03-16",
    "title": "ITE Conecta Programa 3, La consulta a las comunidades que eligen a sus autoridades por usos y costumbres y a las indígenas del Estado"
  },
  {
    "id": 4,
    "fecha": "2023-03-23",
    "title": "ITE Conecta Programa 4, Arte visual urbano con Valeria Álvarez Espejel (VAL)"
  },
  {
    "id": 5,
    "fecha": "2023-03-30",
    "title": "ITE Conecta Programa 5, Centennials y millennials con Alberto Conde"
  },
  {
    "id": 6,
    "fecha": "2023-04-06",
    "title": "ITE Conecta Programa 6, La credencial para votar"
  },
  {
    "id": 7,
    "fecha": "2023-04-13",
    "title": "ITE Conecta Programa 7, Derechos político electorales de las personas con discapacidad"
  },
  {
    "id": 8,
    "fecha": "2023-04-20",
    "title": "ITE Conecta Programa 8, La comunicación oral de las juventudes"
  },
  {
    "id": 9,
    "fecha": "2023-04-27",
    "title": "ITE Conecta Programa 9, La perspectiva de lo público en las niñas y los niños"
  },
  {
    "id": 10,
    "fecha": "2023-05-04",
    "title": "ITE Conecta Programa 10, Constitución de nuevos partidos políticos en Tlaxcala"
  },
  {
    "id": 11,
    "fecha": "2023-05-11",
    "title": "ITE Conecta Programa 11, Las redes sociales como espacio de participación para las y los jóvenes"
  },
  {
    "id": 12,
    "fecha": "2023-05-18",
    "title": "ITE Conecta Programa 12, El derecho al uso de las calles"
  },
  {
    "id": 13,
    "fecha": "2023-05-25",
    "title": "ITE Conecta Programa 13, Elecciones escolares con el Mtro. Norberto Sánchez Briones"
  },
  {
    "id": 14,
    "fecha": "2023-06-01",
    "title": "ITE Conecta Programa 14, Tribus Urbanas, comunidad friki"
  },
  {
    "id": 15,
    "fecha": "2023-06-08",
    "title": "ITE Conecta Programa 15, Inclusión, Lengua de Señas Mexicana con Adriana Tecpa Sartillo"
  },
  {
    "id": 16,
    "fecha": "2023-06-15",
    "title": "ITE Conecta Programa 16, Semana de educación cívica"
  },
  {
    "id": 17,
    "fecha": "2023-06-22",
    "title": "ITE Conecta Programa 17, Derechos de la Comunidad LGBTTTIQA+"
  },
  {
    "id": 18,
    "fecha": "2023-06-29",
    "title": "ITE Conecta Programa 18, El tatuaje como forma de expresión y pertenencia social"
  },
  {
    "id": 19,
    "fecha": "2023-07-06",
    "title": "ITE Conecta Programa 19, Moda, expresión y pertenencia social"
  },
  {
    "id": 20,
    "fecha": "2023-07-13",
    "title": "ITE Conecta Programa 20, Responsabilidad social"
  },
  {
    "id": 21,
    "fecha": "2023-07-20",
    "title": "ITE Conecta Programa 21, Lectura en redes sociales"
  },
  {
    "id": 22,
    "fecha": "2023-07-27",
    "title": "ITE Conecta Programa 22, Ciudadanía digital"
  },
  {
    "id": 23,
    "fecha": "2023-08-03",
    "title": "ITE Conecta Programa 23, Deporte como forma de organización colectiva"
  },
  {
    "id": 24,
    "fecha": "2023-08-10",
    "title": "ITE Conecta Programa 24, Debate juvenil"
  },
  {
    "id": 25,
    "fecha": "2023-08-17",
    "title": "ITE Conecta Programa 25, Lo audiovisual en lo público"
  },
  {
    "id": 26,
    "fecha": "2023-08-24",
    "title": "ITE Conecta Programa 26, Las juventudes indígenas en Tlaxcala"
  },
  {
    "id": 27,
    "fecha": "2023-08-31",
    "title": "ITE Conecta Programa 27, Rap y feminismo"
  },
  {
    "id": 28,
    "fecha": "2023-09-07",
    "title": "ITE Conecta Programa 28, La interacción de las juventudes en un entorno híbrido"
  },
  {
    "id": 29,
    "fecha": "2023-09-14",
    "title": "ITE Conecta Programa 29, Las juventudes y la sustentabilidad"
  },
  {
    "id": 30,
    "fecha": "2023-09-21",
    "title": "ITE Conecta Programa 30, Promoción de la cultura en ámbitos comunitarios"
  },
  {
    "id": 31,
    "fecha": "2023-09-28",
    "title": "ITE Conecta Programa 31, Acciones afirmativas rumbo al Proceso Electoral Local Ordinario 2023-2024"
  },
  {
    "id": 32,
    "fecha": "2023-10-05",
    "title": "ITE Conecta Programa 32, Emprendimiento, factor de empoderamiento en las juventudes"
  },
  {
    "id": 33,
    "fecha": "2023-10-12",
    "title": "ITE Conecta Programa 33, TikTok como medio para la conservación y difusión de la lengua Náhuatl"
  },
  {
    "id": 34,
    "fecha": "2023-10-19",
    "title": "ITE Conecta Programa 34, Preservación de cultura, arte y tradición con el colectivo cultural camaxtli"
  },
  {
    "id": 35,
    "fecha": "2023-10-26",
    "title": "ITE Conecta Programa 35, Baile como expresión artística en jóvenes"
  },
  {
    "id": 36,
    "fecha": "2023-11-02",
    "title": "ITE Conecta Programa 36, Preservación de la cultura tlaxcalteca a través de la música"
  },
  {
    "id": 37,
    "fecha": "2023-11-09",
    "title": "ITE Conecta Programa 37, Monitoreo en medios de comunicación para el PELO 2023-2024"
  },
  {
    "id": 38,
    "fecha": "2023-11-16",
    "title": "ITE Conecta Programa 38, Montañismo: trabajo en equipo que construye ciudadanía"
  },
  {
    "id": 39,
    "fecha": "2023-11-23",
    "title": "ITE Conecta Programa 39, Observación electoral"
  },
  {
    "id": 40,
    "fecha": "2023-11-30",
    "title": "ITE Conecta Programa 40, Proceso Electoral Local Ordinario 2023-2024"
  },
  {
    "id": 41,
    "fecha": "2023-12-07",
    "title": "ITE Conecta Programa 41, Ya comenzó el PELO 2023-2024 ¿Y ahora qué?"
  },
  {
    "id": 42,
    "fecha": "2023-12-14",
    "title": "ITE Conecta Programa 42, Postulación de mujeres para el PELO 2023-2024"
  },
  {
    "id": 43,
    "fecha": "2023-12-21",
    "title": "ITE Conecta Programa 43, Participación política de los jóvenes en el PELO 2023-2024"
  },
  {
    "id": 44,
    "fecha": "2023-12-28",
    "title": "ITE Conecta Programa 44, Participación política de las personas con discapacidad en el PELO 2023-2024"
  },
  {
    "id": 45,
    "fecha": "2024-01-04",
    "title": "ITE Conecta Programa 45, Participación política de las personas, pueblos y comunidades indígenas en el PELO 2023-2024"
  },
  {
    "id": 46,
    "fecha": "2024-01-11",
    "title": "ITE Conecta Programa 46, La postulación de candidaturas de personas de la comunidad LGBTTTIQ+ en el PELO 2023-2024."
  },
  {
    "id": 47,
    "fecha": "2024-01-25",
    "title": "ITE Conecta Programa 47, Lineamientos para garantizar la imparcialidad, neutralidad y equidad en el PELO 2023-2024."
  },
  {
    "id": 48,
    "fecha": "2024-02-01",
    "title": "ITE Conecta Programa 48, Candidaturas Independientes"
  },
  {
    "id": 49,
    "fecha": "2024-02-08",
    "title": "ITE Conecta Programa 49, Reglamento de Asistencia Técnica, Jurídica y Logística a las comunidades que realizan elecciones de Presidencias de Comunidad por el sistema de usos y costumbre"
  },
  {
    "id": 50,
    "fecha": "2024-02-15",
    "title": "ITE Conecta Programa 50, Voto anticipado"
  },
  {
    "id": 51,
    "fecha": "2024-02-22",
    "title": "ITE Conecta Programa 51, Coaliciones en el PELO 2023-2024."
  },
  {
    "id": 52,
    "fecha": "2024-02-29",
    "title": "ITE Conecta Programa 52, Consejos Distritales y Municipales"
  },
  {
    "id": 53,
    "fecha": "2024-03-07",
    "title": "ITE Conecta Programa 53, Topes de gastos de campaña"
  },
  {
    "id": 54,
    "fecha": "2024-03-14",
    "title": "ITE Conecta Programa 54, Programas sociales en el PELO 2023-2024."
  },
  {
    "id": 55,
    "fecha": "2024-03-21",
    "title": "ITE Conecta Programa 55, Registro de Candidaturas"
  },
  {
    "id": 56,
    "fecha": "2024-03-28",
    "title": "ITE Conecta Programa 56, Lineamientos para el registro de candidaturas 8 de 8"
  },
  {
    "id": 57,
    "fecha": "2024-04-04",
    "title": "ITE Conecta Programa 57, Instalación de los Consejos Distritales y Municipales"
  },
  {
    "id": 58,
    "fecha": "2024-04-11",
    "title": "ITE Conecta Programa 58, Medidas de seguridad en las boletas electorales"
  },
  {
    "id": 59,
    "fecha": "2024-04-18",
    "title": "ITE Conecta Programa 59, Sistema Candidatas y Candidatos Conóceles"
  },
  {
    "id": 60,
    "fecha": "2024-04-25",
    "title": "ITE Conecta Programa 60, Procesamiento de la información en torno al registro de candidaturas"
  },
  {
    "id": 61,
    "fecha": "2024-05-02",
    "title": "ITE Conecta Programa 61, Aprobación de candidaturas"
  },
  {
    "id": 62,
    "fecha": "2024-05-09",
    "title": "ITE Conecta Programa 62, Inicio de campañas electorales"
  },
  {
    "id": 63,
    "fecha": "2024-05-16",
    "title": "ITE Conecta Programa 63, Denuncias ciudadanas"
  },
  {
    "id": 64,
    "fecha": "2024-05-23",
    "title": "ITE Conecta Programa 64, ¿Cómo votar?"
  },
  {
    "id": 65,
    "fecha": "2024-05-30",
    "title": "ITE Conecta Programa 65, ¡Vota este 2 de junio!"
  },
  {
    "id": 66,
    "fecha": "2024-06-13",
    "title": "ITE Conecta Programa 66, Resultados de la Jornada Electoral"
  },
  {
    "id": 67,
    "fecha": "2024-06-20",
    "title": "ITE Conecta Programa 67, Asignación de regidurías en el PELO"
  },
  {
    "id": 68,
    "fecha": "2024-06-27",
    "title": "ITE Conecta Programa 68, Participación política de la comunidad LGBTTTIQA+ en el PELO 2023 -2024"
  },
  {
    "id": 69,
    "fecha": "2024-07-04",
    "title": "ITE Conecta Programa 69, Participación política de las personas con discapacidad en el PELO 2023-2024."
  },
  {
    "id": 70,
    "fecha": "2024-07-11",
    "title": "ITE Conecta Programa 70, Participación política de las personas indígenas en el PELO 2023-2024"
  },
  {
    "id": 71,
    "fecha": "2024-07-18",
    "title": "ITE Conecta Programa 71, Participación política de las juventudes en el PELO 2023-2024"
  },
  {
    "id": 72,
    "fecha": "2024-07-25",
    "title": "ITE Conecta Programa 72, Rumbo al parlamento infantil 2024"
  },
  {
    "id": 73,
    "fecha": "2024-08-01",
    "title": "ITE Conecta Programa 73, ¿Están cambiando los resultados electorales?"
  },
  {
    "id": 74,
    "fecha": "2024-08-08",
    "title": "ITE Conecta Programa 74, ¿Habrá elecciones extraordinarias en Tlaxcala?"
  },
  {
    "id": 75,
    "fecha": "2024-08-15",
    "title": "ITE Conecta Programa 75, 30 aniversario del Órgano Constitucional Autónomo Electoral Local."
  },
  {
    "id": 76,
    "fecha": "2024-08-22",
    "title": "ITE Conecta Programa 76, Elecciones por Usos y Costumbres"
  },
  {
    "id": 77,
    "fecha": "2024-08-29",
    "title": "ITE Conecta Programa 77, Ex Fabrica de San Manuel"
  },
  {
    "id": 78,
    "fecha": "2024-09-19",
    "title": "ITE Conecta Programa 78, "
  },
  {
    "id": 79,
    "fecha": "2024-09-26",
    "title": "ITE Conecta Programa 79, Compartiendo experiencias sobre su trayecto en el OPLE"
  },
  {
    "id": 80,
    "fecha": "2024-10-03",
    "title": "ITE Conecta Programa 80, Concurso Nacional de Oratoria, Belisario Domínguez Palencia “Libres por la Palabra Libre”."
  },
  {
    "id": 81,
    "fecha": "2024-10-10",
    "title": "ITE Conecta Programa 81, Designación de una consejería electoral"
  },
  {
    "id": 82,
    "fecha": "2024-10-17",
    "title": "ITE Conecta Programa 82, Proceso Electoral Local Extraordinario 2024"
  },
  {
    "id": 83,
    "fecha": "2024-10-24",
    "title": "ITE Conecta Programa 83, Elecciones Escolares"
  },
  {
    "id": 84,
    "fecha": "2024-10-31",
    "title": "ITE Conecta Programa 84, Observación Electoral"
  },
  {
    "id": 85,
    "fecha": "2024-11-08",
    "title": "ITE Conecta Programa 85, Debates en el Proceso Electoral Local Extraordinario 2024"
  },
  {
    "id": 86,
    "fecha": "2024-11-14",
    "title": "ITE Conecta Programa 86, Consulta Infantil y Juvenil 2024"
  },
  {
    "id": 87,
    "fecha": "2024-11-21",
    "title": "ITE Conecta Programa 87, Jornada Electoral"
  },
  {
    "id": 88,
    "fecha": "2024-11-28",
    "title": "ITE Conecta Programa 88, Cómputos"
  },
  {
    "id": 89,
    "fecha": "2024-12-05",
    "title": "ITE Conecta Programa 89, Participación ciudadana en el PELE 2024"
  },
  {
    "id": 90,
    "fecha": "2024-12-12",
    "title": "ITE Conecta Programa 90, Retos y avances de las personas con discapacidad en el ámbito de lo público"
  },
  {
    "id": 91,
    "fecha": "2024-12-19",
    "title": "ITE Conecta Programa 91, Proceso Electoral Local Extraordinario de Cargos del Poder Judicial Local 2024-2025"
  }
];
