import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../../layout/TitlePages";
import Breadcrumbs from "../../../layout/Breadcrumbs";
import Expandible from "../../../layout/HelperDataTable/Expandible";
import { FilterComponent, useCompleteTableConfig } from "../../../constants";
import { dataAcuerdos2025 } from "../../../data/2025/dataAcuerdos";

const baseUrl = "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2025/";

const PdfLink = ({ url }) => {
  const fullUrl = `${baseUrl}${url}`;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

const TableRow = ({ title, url }) =>
  title && url ? (
    <tr>
      <td>{title.toUpperCase()}</td>
      <td>
        <PdfLink url={url} />
      </td>
    </tr>
  ) : null;

const AcuerdosITE2025 = () => {
  useEffect(() => {
    document.title = `Acuerdos ITE 2025`;
  }, []);

  const columnsAcuerdos = useMemo(
    () => [
      {
        accessorKey: "monthDoc",
        header: "MES",
        footer: "MES",
        size: 30,
        Filter: FilterComponent,
      },
      {
        accessorFn: (row) => `ITE-CG ${row.id}-2025`,
        id: "acuerdo",
        header: "ACUERDO",
        footer: "ACUERDO",
        size: 55,
      },
      {
        accessorFn: (row) =>
          row.nameDoc ? `${row.typeDoc} ${row.nameDoc}` : "",
        id: "titulo",
        header: "TÍTULO",
        footer: "TÍTULO",
        size: 255,
      },
    ],
    []
  );

  const renderDetailPanelAcuerdos = ({ row }) => {
    const anexoTitles = Object.keys(row.original).filter(
      (key) => key.startsWith("titleAnexo") && row.original[key]
    );

    return (
      <Box id="Box">
        <div className="table-responsive">
          <table className="table table-hover table-sm table-bordered table align-middle w-40">
            <thead>
              <tr>
                <td colSpan={2}>
                  <br />
                  <strong>A C U E R D O</strong>
                  <br />
                  <br />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="table-secondary">
                <td>
                  {`${row.original.typeDoc} ITE-CG ${row.original.id}-2025 ${
                    row.original.nameDoc || ""
                  }`}
                </td>
                <td>
                  {row.original.id && (
                    <PdfLink url={row.original.id + ".pdf"} />
                  )}
                </td>
              </tr>
              {anexoTitles.length > 0 && (
                <tr>
                  <td colSpan={2}>
                    <br />
                    <strong>A N E X O</strong>
                    <br />
                    <br />
                  </td>
                </tr>
              )}
              {anexoTitles.map((key) => {
                const index = key.replace("titleAnexo", "");
                return (
                  <TableRow
                    key={index}
                    title={row.original[key]}
                    url={`${row.original.id}.${index}.pdf`}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </Box>
    );
  };

  const tableAcuerdos = useCompleteTableConfig(
    dataAcuerdos2025,
    columnsAcuerdos,
    renderDetailPanelAcuerdos
  );

  return (
    <>
      <Breadcrumbs path={[{ label: `Acuerdos 2025` }]} />
      <TitlePages title="Acuerdos ITE" subTitle="Acuerdos ITE 2025" />
      <Expandible />
      <MaterialReactTable table={tableAcuerdos} />
    </>
  );
};

export default AcuerdosITE2025;

PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
};

TableRow.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
