export const dataEstrados = [
  
   // bajar el 24 de enero
   {
    id: 1,
    numCedula: 135,
  },
  // bajar el 24 de enero
  {
    id: 2,
    numCedula: 138,
  },
  // bajar el 24 de enero
  {
    id: 3,
    numCedula: 139,
  },
  // bajar el 27 de enero
  {
    id: 4,
    numCedula: 147,
  },
  // bajar el 27 de enero
  {
    id: 5,
    numCedula: 148,
  },
  // bajar el 29 de enero
  {
    id: 6,
    numCedula: 185,
  },

];

export const dataConvocatorias = [
  // {
  //   id: 1,
  //   nameFile: "Convocatoria-Licitacion-Publica-Nacional-ITE-01-2024",
  //   linkFile:
  //     "https://itetlax.org.mx/assets/pdf/convocatorias/Convocatoria-Licitacion-Publica-Nacional-ITE-01-2024.pdf",
  // },
];

export const dataConvocatoriasApartado = [
  {
    id: 1,
    nameFile: "Convocatoria Licitacion ITE-LP-06-2024",
    linkFile:
      "https://itetlax.org.mx/assets/pdf/convocatorias/Convocatoria-ITE-LP-06-2024.pdf",
  },
];
