import { Tooltip } from "bootstrap";

export const dataPartidosPoliticos = [
  /* {
    id: 1,
    titleItem: "Partidos Políticos Nacionales",
    typeDocument: "pdf",
    linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/1.pdf",
  },
  {
    id: 2,
    titleItem: "Partidos Políticos Locales",
    typeDocument: "pdf",
    linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/2.pdf",
  }, */
  {
    id: 1,
    titleItem: "Dirigencias Partidos Politicos Nacionales y Locales",
    typeDocument: "pdf",
    linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/3.pdf",
  },
  
];

export const dataPartidosPoliticos2 = [
  {
    id: 1,
    titleItem: "Documentos Básicos",
    flushID: "DocumentosBasicos",
    children: [
      {
        id: 1,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_pan.png",
        alt: "Partido Acción Nacional",
        titleItem: "Documentos Básicos PAN",
        typeDocument: "link",
        linkItem:
          "https://www.pan.org.mx/documentos/estatutos ",
      },
      {
        id: 2,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_pri.png",
        alt: "Partido Revolucionario Institucional",
        titleItem: "Documentos Básicos PRI",
        typeDocument: "link",
        linkItem:
          "https://pri.org.mx/ElPartidoDeMexico/nuestropartido/documentos.aspx ",
      },
      {
        id: 3,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_pt.png",
        alt: "Partido del Trabajo",
        titleItem: "Documentos Básicos PT",
        typeDocument: "link",
        linkItem: [
            "https://partidodeltrabajo.com.mx/wp-content/uploads/2022/12/estatutos_2021.pdf",
            "https://partidodeltrabajo.com.mx/wp-content/uploads/2023/03/1-Declaracion-de-Principios-2021.pdf ",
            "https://partidodeltrabajo.com.mx/wp-content/uploads/2023/03/2-Programa-de-Accion-2021.pdf "
        ],
      },
      {
        id: 4,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_pvem.png",
        alt: "Partido Verde Ecologista de México",
        titleItem: "Documentos Básicos PVEM",
        typeDocument: "link",
        linkItem: [
          "https://www.partidoverde.org.mx/images/2023/ESTATUTOS-PVEM_Vigentes_140322.pdf",
          "https://www.partidoverde.org.mx/images/2023/DECLARACION_DE_PRINCIPIOS_PVEM.pdf ",
          "https://www.partidoverde.org.mx/images/2023/PROGRAMA_DE_ACCION_PVEM.pdf ",
        ],
      },
      {
        id: 5,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_mc.png",
        alt: "Movimiento Ciudadano",
        titleItem: "Documentos Básicos MC",
        typeDocument: "link",
        linkItem:
          "https://movimientociudadano.mx/documentos-basicos",
      },
      {
        id: 6,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_pac.png",
        alt: "Partido Alianza Ciudadana",
        titleItem: "Documentos Básicos PAC",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/282.pdf",
      },
      {
        id: 7,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_morena.png",
        alt: "Movimiento de Regeneracion Nacional ",
        titleItem: "Documentos Básicos MORENA",
        typeDocument: "link",
        linkItem: [
          "https://morena.si/wp-content/uploads/2014/12/Estatuto-de-MORENA-Publicado-DOF-5-nov-2014.pdf",
          "https://morena.si/wp-content/uploads/2014/12/declaracion-de-principios-de-morena1.pdf ",
          "https://www.iem.org.mx/documentos/partidos_politicos/morena/programa_morena.pdf",
        ],
      },
      {
        id: 8,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_na.png",
        alt: "Partido Nueva Alianza Tlaxcala",
        typeDocument: "pdf",
        titleItem: "Documentos Básicos PNA Tlaxcala",
        linkItem:
          "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2019/37.pdf ",
      },
      {
        id: 9,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_rsp.png",
        alt: "Redes Sociales Progresistas Tlaxcala",
        titleItem: "Documentos Básicos RSP Tlaxcala",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2023/95.pdf ",
      },
      {
        id: 10,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_fxm.png",
        alt: "Fuerza por México Tlaxcala",
        titleItem: "Documentos Básicos FXM Tlaxcala",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2022/56.pdf",
      },
      {
        id: 11,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_prd.png",
        alt: "Paartido de la Revolución Democratica Tlaxcala",
        titleItem: "Documentos Básicos PRD Tlaxcala",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/prd_texto.pdf",
      },
    ],
  },
  {
    id: 2,
    titleItem: "Plataformas Electorales",
    flushID: "PlataformasElectorales",
    children: [
      {
        id: 1,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_pan.png",
        alt: "Partido Acción Nacional",
        titleItem: "Plataforma Electoral PAN Tlaxcala",
        typeDocument: "pdf",
        linkItem:
          "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/54.pdf ",
      },
      {
        id: 2,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_pri.png",
        alt: "Partido Revolucionario Institucional",
        titleItem: "Plataforma Electoral PRI Tlaxcala",
        typeDocument: "pdf",
        linkItem:
          "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/55.pdf ",
      },
      {
        id: 3,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_pt.png",
        alt: "Partido del Trabajo",
        titleItem: "Plataforma Electoral PT Tlaxcala",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/46.pdf", 
      },
      {
        id: 4,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_pvem.png",
        alt: "Partido Verde Ecologista de México",
        titleItem: "Plataforma Electoral PVEM Tlaxcala",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/47.pdf",
      },
      {
        id: 5,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_mc.png",
        alt: "Movimiento Ciudadano",
        titleItem: "Plataforma Electoral MC Tlaxcala",
        typeDocument: "pdf",
        linkItem:
          "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/48.pdf ",
      },
      {
        id: 6,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_pac.png",
        alt: "Partido Alianza Ciudadana",
        titleItem: "Plataforma Electoral PAC Tlaxcala",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/49.pdf",
      },
      {
        id: 7,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_morena.png",
        alt: "Movimiento de Regeneracion Nacional ",
        titleItem: "Plataforma Electoral MORENA Tlaxcala",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/50.pdf",
      },
      {
        id: 8,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_na.png",
        alt: "Partido Nueva Alianza Tlaxcala",
        typeDocument: "pdf",
        titleItem: "Plataforma Electoral PNA Tlaxcala",
        linkItem:
          "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/51.pdf ",
      },
      {
        id: 9,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_rsp.png",
        alt: "Redes Sociales Progresistas Tlaxcala",
        titleItem: "Plataforma Electoral RSP Tlaxcala",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/52.pdf ",
      },
      {
        id: 10,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_fxm.png",
        alt: "Fuerza por México Tlaxcala",
        titleItem: "Plataforma Electoral FXM Tlaxcala",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/53.pdf ",
      },
      {
        id: 11,
        img: "https://itetlax.org.mx/assets/img/iconsPP/logo_prd.png",
        alt: "Paartido de la Revolución Democratica Tlaxcala",
        titleItem: "Plataforma Electoral PRD Tlaxcala",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2024/45.pdf",
      },
    ],
  },

];


