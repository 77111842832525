import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFileZipper,
  faLink,
} from "@fortawesome/free-solid-svg-icons";

const iconMap = {
  video: { icon: faFileVideo, className: "bg-secondary" },
  img: { icon: faFileImage, className: "bg-primary" },
  excel: { icon: faFileExcel, className: "bg-success" },
  pdf: { icon: faFilePdf, className: "bg-danger" },
  link: { icon: faLink, className: "bg-info" },
  zip: { icon: faFileZipper, className: "bg-warning" },
};

const ListBadge = ({ ifNumbered, listsBadgeItem, clasName }) => {
  return (
    <ol className={`list-group ${ifNumbered} ${clasName}`}>
      {listsBadgeItem.map(({ id, img, alt, titleItem, linkItem, typeDocument }) => (
        <li key={id} className="list-group-item d-flex justify-content-between align-items-center">
          {img && (
            <img 
                src={img} 
                alt={alt} 
                style={{ width: '5em', height: 'auto' }} 
            />
          )}
          <div className="ms-2 me-auto">
            <div>{titleItem}</div>
          </div>
          {linkItem && iconMap[typeDocument] && (
            <div>
              {Array.isArray(linkItem) ? (
                linkItem.map((link, index) => (
                  <a key={index} href={link} target="_blank" rel="noreferrer" className="me-2">
                    <span className={`badge ${iconMap[typeDocument].className}`}>
                      <FontAwesomeIcon icon={iconMap[typeDocument].icon} />
                    </span>
                  </a>
                ))
              ) : (
                <a href={linkItem} target="_blank" rel="noreferrer" className="me-2">
                  <span className={`badge ${iconMap[typeDocument].className}`}>
                    <FontAwesomeIcon icon={iconMap[typeDocument].icon} />
                  </span>
                </a>
              )}
            </div>
          )}
        </li>
      ))}
    </ol>
  );
};

export default ListBadge;