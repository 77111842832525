export const dataArt632024 = [
  {
    fraccion: "I",
    titulo: "Marco Normativo Aplicable de Sujeto Obligado",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F01.xlsx",
    managerId: null,
  },
  {
    id: 1,
    fraccion: "II",
    titulo: "Estructura Orgánica",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "II-A",
    titulo: "Estructura Orgánica",
    cumplimiento: "Aplica",
    managerId: 1,
  },
  {
    fraccion: "II-B",
    titulo: "Organigrama",
    cumplimiento: "Aplica",
    managerId: 1,
  },
  {
    fraccion: "III",
    titulo: "Facultades de las áreas",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "IV",
    titulo: "Metas y objetivos de las áreas",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "V",
    titulo: "Los indicadores relacionados con temas de interés",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "VI",
    titulo: "Indicadores de objetivos y resultados",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "VII",
    titulo: "Directorio de servidores públicos",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    id: 2,
    fraccion: "VIII",
    titulo: "Remuneración bruta y neta",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "VIII-A",
    titulo: "Remuneración bruta y neta",
    cumplimiento: "Aplica",
    managerId: 2,
  },
  {
    fraccion: "VIII-B",
    titulo: "Remuneración bruta y neta",
    cumplimiento: "Aplica",
    managerId: 2,
  },
  {
    fraccion: "IX",
    titulo: "Gastos por concepto de viáticos y representación",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    id: 3,
    fraccion: "X",
    titulo: "Personal plazas y vacantes",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "X-A",
    titulo: "Plazas vacantes del personal de base y confianza",
    cumplimiento: "Aplica",
    managerId: 3,
  },
  {
    fraccion: "X-B",
    titulo:
      "Total de plazas vacantes y ocupadas del personal de base y confianza",
    cumplimiento: "Aplica",
    managerId: 3,
  },
  {
    fraccion: "XI",
    titulo: "Contrataciones de servicios profesionales por honorarios",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XII",
    titulo:
      "Declaraciones de Situación patrimonial de los(as) servidores(as) públicos(as)",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F12.xlsx",
    managerId: null,
  },
  {
    fraccion: "XIII",
    titulo: "Domicilio de la Unidad de Transparencia",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XIV",
    titulo: "Concursos para ocupar cargos públicos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F13.xlsx",
    managerId: null,
  },
  {
    id: 4,
    fraccion: "XV",
    titulo: "Subsidios, estímulos y apoyos",
    cumplimiento: "No Aplica",
    managerId: null,
  },
  {
    fraccion: "XV-A",
    titulo: "Programas sociales",
    cumplimiento: "No Aplica",
    managerId: 4,
  },
  {
    fraccion: "XV-B",
    titulo: "Padrón de beneficiarios de programas sociales",
    cumplimiento: "No Aplica",
    managerId: 4,
  },
  {
    id: 5,
    fraccion: "XVI",
    titulo: "Condiciones generales de trabajo y sindicatos",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XVI-A",
    titulo: "Normatividad laboral",
    cumplimiento: "Aplica",
    managerId: 5,
  },
  {
    fraccion: "XVI-B",
    titulo: "Recursos públicos entregados a sindicatos",
    cumplimiento: "Aplica",
    managerId: 5,
  },
  {
    fraccion: "XVII",
    titulo: "Información curricular de los(as) servidores(as) públicas(os)",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XVIII",
    titulo: "Sanciones administrativa",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F18.xlsx",
    managerId: null,
  },
  {
    fraccion: "XIX",
    titulo: "Servicios que ofrece el sujeto obligado",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F19.xlsx",
    managerId: null,
  },
  {
    fraccion: "XX",
    titulo: "Trámites que se realizan",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F20.xlsx",
    managerId: null,
  },
  {
    id: 6,
    fraccion: "XXI",
    titulo: "Presupuesto asignado",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXI-A",
    titulo: "Presupuesto asignado anual",
    cumplimiento: "Aplica",
    managerId: 6,
  },
  {
    fraccion: "XXI-B",
    titulo: "Ejercicio de los egresos presupuestarios",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXI-C",
    titulo: "Cuenta pública",
    cumplimiento: "Aplica",
    managerId: 6,
  },
  {
    fraccion: "XXII",
    titulo: "Deuda Pública",
    cumplimiento: "No Aplica",
    managerId: null,
  },
  {
    id: 7,
    fraccion: "XXIII",
    titulo: "Gastos de publicidad oficial",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXIII-A",
    titulo: "Programa Anual de Comunicación Social o equivalente",
    cumplimiento: "Aplica",
    managerId: 7,
  },
  {
    fraccion: "XXIII-B",
    titulo: "Contratación de servicios de publicidad oficial",
    cumplimiento: "Aplica",
    managerId: 7,
  },
  {
    fraccion: "XXIII-C",
    titulo: "Utilización de los tiempos oficiales en radio y tv",
    cumplimiento: "Aplica",
    managerId: 7,
  },
  {
    fraccion: "XXIII-D",
    titulo:
      "Hipervínculo a información de tiempos oficiales en radio y televisión",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXIV",
    titulo: "Resultados de auditorías realizadas",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F24.xlsx",
    managerId: null,
  },
  {
    fraccion: "XXV",
    titulo: "Resultados de la dictaminación de los estados financieros",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXVI",
    titulo: "Personas que usan recursos públicos",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXVII",
    titulo:
      "Las concesiones, contratos, convenios, permisos, licencias o autorizaciones otorgadas",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    id: 8,
    fraccion: "XXVIII",
    titulo: "Resultados adjudicaciones, invitaciones y licitaciones",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXVIII-A",
    titulo:
      "Procedimientos de licitación pública e invitación a cuando menos tres personas",
    cumplimiento: "Aplica",
    managerId: 8,
  },
  {
    fraccion: "XXVIII-B",
    titulo: "Procedimientos de adjudicación directa",
    cumplimiento: "Aplica",
    managerId: 8,
  },
  {
    fraccion: "XXIX",
    titulo: "Informes emitidos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F29_1.xlsx",
    excel2: "LTAIPT_A63F29_2.xlsx",
    excel3: "LTAIPT_A63F29_3.xlsx",
    excel4: "LTAIPT_A63F29_4.xlsx",
    managerId: null,
  },
  {
    fraccion: "XXX",
    titulo: "Estadísticas generadas",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F30.xlsx",
    managerId: null,
  },
  {
    id: 9,
    fraccion: "XXXI",
    titulo: "Informe financiero",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXXI-A",
    titulo: "Gasto por Capítulo, Concepto y Partida",
    cumplimiento: "Aplica",
    managerId: 9,
  },
  {
    fraccion: "XXXI-B",
    titulo: "Informes financieros contables, presupuestales y programáticos",
    cumplimiento: "Aplica",
    managerId: 9,
  },
  {
    fraccion: "XXXII",
    titulo: "Padrón de proveedores y contratistas",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXXIII",
    titulo:
      "Convenios de coordinación, de concertación con el sector social o privado",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F33_1.xlsx",
    excel2: "LTAIPT_A63F33_2.xlsx",
    excel3: "LTAIPT_A63F33_3.xlsx",
    excel4: "LTAIPT_A63F33_4.xlsx",
    managerId: null,
  },
  {
    id: 10,
    fraccion: "XXXIV",
    titulo: "Inventario",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXXIV-A",
    titulo: "Inventario de altas practicadas a bienes muebles",
    cumplimiento: "Aplica",
    managerId: 10,
  },
  {
    fraccion: "XXXIV-B",
    titulo: "Inventario de altas practicadas a bienes muebles",
    cumplimiento: "Aplica",
    managerId: 10,
  },
  {
    fraccion: "XXXIV-C",
    titulo: "Inventario de bajas practicadas a bienes muebles",
    cumplimiento: "Aplica",
    managerId: 10,
  },
  {
    fraccion: "XXXIV-D",
    titulo: "Inventario de bienes inmuebles",
    cumplimiento: "Aplica",
    managerId: 10,
  },
  {
    fraccion: "XXXIV-E",
    titulo: "Inventario de altas practicadas a bienes inmuebles",
    cumplimiento: "Aplica",
    managerId: 10,
  },
  {
    fraccion: "XXXIV-F",
    titulo: "Inventario de bajas practicadas a bienes inmuebles",
    cumplimiento: "Aplica",
    managerId: 10,
  },
  {
    fraccion: "XXXIV-G",
    titulo: "Inventario de bienes muebles e inmuebles donados",
    cumplimiento: "Aplica",
    managerId: 10,
  },
  {
    id: 11,
    fraccion: "XXXV",
    titulo: "Recomendaciones derechos humanos",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXXV-A",
    titulo: "Recomendaciones de organismos garantes de derechos humanos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F35A.xlsx",
    managerId: 11,
  },
  {
    fraccion: "XXXV-B",
    titulo: "Casos especiales de organismos garantes de derechos humanos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F35B.xlsx",
    managerId: 11,
  },
  {
    fraccion: "XXXV-C",
    titulo: "Recomendaciones de organismos internacionales de derechos humanos",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F35C.xlsx",
    managerId: 11,
  },
  {
    fraccion: "XXXVI",
    titulo: "Resoluciones y laudos emitidos",
    cumplimiento: "No Aplica",
    managerId: null,
  },
  {
    id: 12,
    fraccion: "XXXVII",
    titulo: "Participación ciudadana",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXXVII-A",
    titulo: "Mecanismos de participación ciudadana",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F37A.xlsx",
    managerId: 12,
  },
  {
    fraccion: "XXXVII-B",
    titulo: "Resultado de los mecanismos de participación",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F37B.xlsx",
    managerId: 12,
  },
  {
    id: 13,
    fraccion: "XXXVIII",
    titulo: "Otros programas",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXXVIII-A",
    titulo: "Programas que ofrecen",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F38A.xlsx",
    managerId: 13,
  },
  {
    fraccion: "XXXVIII-B",
    titulo: "Trámites para acceder a programas que ofrecen",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F38B.xlsx",
    managerId: 13,
  },
  {
    id: 14,
    fraccion: "XXXIX",
    titulo: "Actas y resoluciones Comité de Transparencia",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XXXIX-A",
    titulo: "Informe de sesiones del Comité de Transparencia",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F39A.xlsx",
    managerId: 14,
  },
  {
    fraccion: "XXXIX-B",
    titulo: "Informe de Resoluciones del Comité de Transparencia",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F39B.xlsx",
    managerId: 14,
  },
  {
    fraccion: "XXXIX-C",
    titulo: "Integrantes del Comité de Transparencia",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F39C.xlsx",
    managerId: 14,
  },
  {
    fraccion: "XXXIX-D",
    titulo: "Calendario de sesiones ordinarias del Comité de Transparencia",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F39D.xlsx",
    managerId: 14,
  },
  {
    id: 15,
    fraccion: "XL",
    titulo: "Evaluación y encuesta programas financiados",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XL-A",
    titulo:
      "Evaluaciones y encuestas a programas financiados con recursos públicos",
    cumplimiento: "Aplica",
    managerId: 15,
  },
  {
    fraccion: "XL-B",
    titulo: "Encuestas sobre programas financiados con recursos públicos",
    cumplimiento: "Aplica",
    managerId: 15,
  },
  {
    fraccion: "XLI",
    titulo: "Estudios financiados con recursos públicos",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    id: 16,
    fraccion: "XLII",
    titulo: "Jubilados y pensionados",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XLII-A",
    titulo: "Hipervínculo al listado de pensionados y jubilados",
    cumplimiento: "Aplica",
    managerId: 16,
  },
  {
    fraccion: "XLII-B",
    titulo: "Listado de jubilados y pensionados y el monto que reciben",
    cumplimiento: "Aplica",
    managerId: 16,
  },
  {
    id: 17,
    fraccion: "XLIII",
    titulo: "Ingresos",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XLIII-A",
    titulo: "Ingresos recibidos por cualquier concepto por el sujeto obligado",
    cumplimiento: "Aplica",
    managerId: 17,
  },
  {
    fraccion: "XLIII-B",
    titulo: "Responsables de recibir, administrar y ejercer los ingresos",
    cumplimiento: "Aplica",
    managerId: 17,
  },
  {
    id: 18,
    fraccion: "XLIV",
    titulo: "Donaciones",
    cumplimiento: "No Aplica",
    managerId: null,
  },
  {
    fraccion: "XLIV-A",
    titulo: "Donaciones en dinero realizadas",
    cumplimiento: "No Aplica",
    managerId: 18,
  },
  {
    fraccion: "XLIV-B",
    titulo: "Donaciones en especie realizadas",
    cumplimiento: "No Aplica",
    managerId: 18,
  },
  {
    id: 19,
    fraccion: "XLV",
    titulo: "Catálogo de disposición documental y guía simple de archivos",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XLV-A",
    titulo: "",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F45A_1.xlsx",
    excel2: "LTAIPT_A63F45A_2.xlsx",
    excel3: "LTAIPT_A63F45A_3.xlsx",
    managerId: 19,
  },
  {
    fraccion: "XLV-B",
    titulo: "",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F45B_1.xlsx",
    excel2: "LTAIPT_A63F45B_2.xlsx",
    excel3: "LTAIPT_A63F45B_3.xlsx",
    managerId: 19,
  },
  {
    fraccion: "XLV-C",
    titulo: "",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F45C_1.xlsx",
    excel2: "LTAIPT_A63F45C_2.xlsx",
    excel3: "LTAIPT_A63F45C_3.xlsx",
    managerId: 19,
  },
  {
    id: 21,
    fraccion: "XLVI",
    titulo: "Actas de sesiones",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XLVI-A",
    titulo: "Actas del Consejo Consultivo",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F46A_1.xlsx",
    excel2: "LTAIPT_A63F46A_2.xlsx",
    excel3: "LTAIPT_A63F46A_3.xlsx",
    managerId: 21,
  },
  {
    fraccion: "XLVI-B",
    titulo: "Opiniones y recomendaciones del Consejo Consultivo",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F46B_1.xlsx",
    excel2: "LTAIPT_A63F46B_2.xlsx",
    excel3: "LTAIPT_A63F46B_3.xlsx",
    managerId: 21,
  },
  {
    id: 22,
    fraccion: "XLVII",
    titulo: "Más información relacionada",
    cumplimiento: "Aplica",
    managerId: null,
  },
  {
    fraccion: "XLVII-A",
    titulo: "Información de interés público",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F47A.xlsx",
    managerId: 22,
  },
  {
    fraccion: "XLVII-B",
    titulo: "Preguntas frecuentes",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F47B.xlsx",
    managerId: 22,
  },
  {
    fraccion: "XLVII-C",
    titulo: "Transparencia proactiva",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F47C.xlsx",
    managerId: 22,
  },
  {
    fraccion: "C",
    titulo: "Obligaciones aplicables",
    cumplimiento: "Aplica",
    excel1: "LTAIPT_A63F100.xlsx",
    managerId: null,
  },
];
