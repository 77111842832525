import React, { useEffect } from "react";
import NavbarEnlaces from "../../layout/NavbarEnlaces";
import ListBadge from "../../layout/ListBadge";
import Accordion from "../../layout/Accordion/Accordion";
import AccordionItem from "../../layout/Accordion/AccordionItem";
import { dataPartidosPoliticos } from "../../data/dataEnlacesPP";
import { dataPartidosPoliticos2 } from "../../data/dataEnlacesPP";

// Componente para mostrar Partidos Políticos
export const PartidosPoliticos = () => {
  useEffect(() => {
    document.title = `Partidos Políticos`;
  }, []);

  return (
    <>
      <NavbarEnlaces title="Partidos Políticos" />
      <div>
        <ListBadge ifNumbered={""} listsBadgeItem={dataPartidosPoliticos} />
      </div>
    </>
  );
};

// Componente para mostrar Partidos Políticos 2
export const PartidosPoliticos2 = () => {
  useEffect(() => {
    document.title = `Metas MSPEN`;
  }, []);

  return (
    <>
      <Accordion idAccordion="PartidosPoliticos2">
        {dataPartidosPoliticos2.map((infoAccordion) => (
          <AccordionItem
            key={infoAccordion.id}
            flushID={infoAccordion.flushID}
            titleItem={infoAccordion.titleItem}
            contentItem={
              <ListBadge
                ifNumbered={""}
                listsBadgeItem={infoAccordion.children}
              />
              
            }
            idAccordion="PartidosPoliticos2"
          />
        ))}
      </Accordion>
    </>
  );
};

// Componente principal que agrupa los demás componentes
export default function MainComponent() {
  return (
    <div>
      <PartidosPoliticos />
      <PartidosPoliticos2 />
    </div>
  );
}


