const baseUrl = "https://itetlax.org.mx/assets/img/";

export const modalsInitData = [
/*   {
   id: 1,
   imgLink: "https://itetlax.org.mx/assets/img/imgPopup/ordendeldia.png",
   url: "",
   linkToo: "",
  },  */
];
