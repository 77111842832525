

// ------------ A C U E R D O S    I T E ------------
export const dataAcuerdos2025 = [
  {
    id: 1,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "SE REDECUA DISTRIBUCION DE PRERROGATIVAS A PP",
    titleAnexo1: "FINANCIAMIENTO PUBLICO ANUAL",
    titleAnexo2: "FINANCIAMIENTO PUB ACT ESPECIFICAS",
  },
  {
    id: 2,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "ACTUALIZACION Y CANLENDARIZACION DE MULTAS PP",
    titleAnexo1: "ACTUALIZACION CALENDARIZACION SANCIONES",
    titleAnexo2: "CALENDARIZADO DE SANCIONES",
  },
  {
    id: 3,
    typeDoc: "RESOLUCION",
    monthDoc: "ENE",
    nameDoc:
      "MODIFICACION DE DOCUMENTOS BASICOS FXMT",
  },
];

// ------------ A C U E R D O S    I N E ------------
export const dataAcuerdosINE2025 = [
 /*  {
    id: 1,
    typeDoc: "ACUERDO",
    numDoc: "INE/CG2267/2025",
    nameDoc:
      "NOMBRE DEL DOCUMENTO",
  }, */

];
