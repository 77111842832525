import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { MaterialReactTable } from "material-react-table";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import TitlePages from "../../../layout/TitlePages";
import Breadcrumbs from "../../../layout/Breadcrumbs";
import Expandible from "../../../layout/HelperDataTable/Expandible";
import { FilterComponent, useCompleteTableConfig } from "../../../constants";
import { dataBoletines } from "../../../data/dataBoletines";
const baseUrl = "https://itetlax.org.mx/assets/pdf/boletines/";

const PdfLink = ({ url }) => {
  const fullUrl = `${baseUrl}${url}`;
  return (
    <a href={fullUrl} target="_blank" rel="noreferrer">
      <FontAwesomeIcon icon={faFilePdf} className="btn btn-danger" />
    </a>
  );
};

const TableRow = ({ title, url }) =>
  title && url ? (
    <tr>
      <td>{title.toUpperCase()}</td>
      <td>
        <PdfLink url={url} />
      </td>
    </tr>
  ) : null;

const BoletinesTable = ({ year }) => {
  const data = useMemo(() => dataBoletines[year] || [], [year]);

  useEffect(() => {
    document.title = `Boletines ITE ${year}`;
  }, [year]);

  const columns = useMemo(
    () => [
      
      {
        accessorKey: "monthBoletin",
        header: "MES",
        footer: "MES",
        size: 30,
        Filter: FilterComponent,
      },
      {
        accessorKey: "id",
        header: "No.",
        footer: "No.",
        size: 30,
        Filter: FilterComponent,
      },
      {
        accessorKey: "nameBoletin",
        header: "BOLETÍN",
        footer: "BOLETÍN",
        size: 55,
      },
    ],
    []
  );

  const renderDetailPanelBoletines = ({ row }) => {
    return (
      <Box id="Box">
        <div className="table-responsive">
          <table className="table table-hover table-sm table-bordered table align-middle w-40">
            <thead>
              <tr>
                <td colSpan={3}></td>
              </tr>
            </thead>
            <tbody>
              <tr className="table-secondary">
                <td>Descarga</td>
                <td>
                  {row.original.id && (
                    <PdfLink url={`${year}/${row.original.id}.pdf`} />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Box>
    );
  };

  const tableBoletines = useCompleteTableConfig(
    data,
    columns,
    renderDetailPanelBoletines
  );

  return (
    <>
      <Breadcrumbs path={[{ label: `Boletines ${year}` }]} />
      <TitlePages title="Boletines ITE" subTitle={`Boletines ITE ${year}`} />
      <Expandible />
      <MaterialReactTable table={tableBoletines} />
    </>
  );
};

export default BoletinesTable;

PdfLink.propTypes = {
  url: PropTypes.string.isRequired,
};

TableRow.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

BoletinesTable.propTypes = {
  year: PropTypes.string.isRequired,
};
