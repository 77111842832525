export const dataNormatividad = [
  {
    id: 1,
    nameDoc: "Ley General de Archivo",
    post: "DOF 15/06/2018",
    lastUpdate: "DOF 19/01/2023",
    pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/1",
    pdfMobile: "https://itetlax.org.mx/assets/pdf/normatividad/lGenerales/1.1",
    word: "https://itetlax.org.mx/assets/word/normatividad/lGenerales/1.doc",
    excel: "",
  },
  {
    id: 2,
    nameDoc: "Ley de Archivos del Estado de Tlaxcala",
    post: "10/05/2021",
    lastUpdate: "03/10/2023",
    pdf: "https://itetlax.org.mx/assets/pdf/normatividad/lEstatales/3",
    pdfMobile: "",
    word: "https://itetlax.org.mx/assets/word/normatividad/lEstatales/3",
    excel: "",
  },
  {
    id: 3,
    nameDoc: "Acuerdo ITE-CG 04-2023 Reforma del Reglamento Interior del ITE",
    post: "",
    lastUpdate: "",
    pdf: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2023/4",
    pdfMobile: "",
    word: "",
    excel: "",
  },
  {
    id: 4,
    nameDoc: "Reglamento Interior del Instituto Tlaxcalteca de Elecicones",
    post: "",
    lastUpdate: "",
    pdf: "https://itetlax.org.mx/assets/pdf/transparencia/2",
    pdfMobile: "",
    word: "",
    excel: "",
  },
  {
    id: 5,
    nameDoc: "Acuerdo ITE-CG 23-2022 se aprueba el Reglamento de Archivos",
    post: "",
    lastUpdate: "",
    pdf: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2022/23",
    pdfMobile: "",
    word: "",
    excel: "",
  },
  {
    id: 6,
    nameDoc:
      "Reglamento del Instituto Tlaxcalteca de Elecciones en Materia de Archivos (Acuerdo ITE-CG 23-2022)",
    post: "",
    lastUpdate: "",
    pdf: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2022/23.1",
    pdfMobile: "",
    word: "",
    excel: "",
  },
  {
    id: 7,
    nameDoc:
      "Lineamientos para el uso del correo electrónico institucional del Instituto Tlaxcalteca De Elecciones.",
    post: "",
    lastUpdate: "",
    pdf: "9",
    pdfMobile: "",
    word: "",
    excel: "",
  },
];

export const dataPlaneacion = [
  {
    id: 1,
    nameDoc: "Programa Anual de Desarrollo Archivístico 2022",
    pdf: "2",
  },
  {
    id: 2,
    nameDoc: "Programa Anual de Desarrollo Archivístico 2023",
    word: "3",
  },
  {
    id: 3,
    nameDoc:
      "Informe Anual de Cumplimiento del Programa de Desarrollo Archivístico",
    pdf: "4",
  },
  {
    id: 4,
    nameDoc: "Integración de Sistema Institucional de Archivo",
    pdf: "11",
  },
  {
    id: 5,
    nameDoc: "Sistema Institucional de Archivos (SIA)",
    pdf: "11",
  },
  {
    id: 6,
    nameDoc: "Programa Anual de Desarrollo Archivístico 2024",
    word: "9",
  },
  {
    id: 7,
    nameDoc:
      "Informe Anual de cumplimiento del Programa de Desarrollo Archivístico 2023",
    word: "10",
  },
];
export const dataInstrumentos = [
  {
    id: 1,
    nameDoc: "Cuadro General de Clasificación Archivística",
    post: "06/11/2023",
    lastUpdate: "",
    pdf: "1",
    word: "1",
    excel: "",
  },
  {
    id: 2,
    nameDoc: "Catálogo de Disposición Documental",
    post: "06/11/2023",
    lastUpdate: "",
    pdf: "8",
    word: "8",
    excel: "",
  },
  {
    id: 3,
    nameDoc: "Guías de Archivo Documental",
    post: "",
    lastUpdate: "",
    pdf: "",
    word: "",
    excel: "",
    subRows: "",
  },
  {
    id: 4,
    nameDoc: "Inventario de Transferencias",
    post: "",
    lastUpdate: "",
    pdf: "",
    word: "",
    excel: "",
    subRows: "",
  },
  {
    id: 5,
    nameDoc: "Ficha Técnica De Valoración Documental",
    post: "03/03/2023",
    lastUpdate: "",
    pdf: "5",
    word: "5",
    excel: "",
  },
  {
    id: 6,
    nameDoc: "1. FORMATO INVENTARIO GENERAL DE ARCHIVO DE TRÁMITE",
    post: "",
    lastUpdate: "",
    pdf: "",
    word: "1.xlsx",
    excel: "",
  },
  {
    id: 7,
    nameDoc: "2. FORMATO INVENTARIO DE TRANSFERENCIA PRIMARIA",
    post: "",
    lastUpdate: "",
    pdf: "",
    word: "2.xlsx",
    excel: "",
  },
  {
    id: 8,
    nameDoc: "3. FORMATO INVENTARIO DE TRANSFERENCIA SECUNDARIA",
    post: "",
    lastUpdate: "",
    pdf: "",
    word: "3.xlsx",
    excel: "",
  },
  {
    id: 9,
    nameDoc: "4. FORMATO INVENTARIO DE BAJA DOCUMENTAL",
    post: "",
    lastUpdate: "",
    pdf: "",
    word: "4.xlsx",
    excel: "",
  },
];
