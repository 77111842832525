export const dataMSPEN = [
  /* {
    id: 1,
    titleItem: "Representantes Acreditados",
    flushID: "RepresentantesAcreditados",
    children: [
      {
        id: 1,
        titleItem: "Representantes ante al ITE",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/1.pdf",
      },
    ],
  }, */
  /* {
    id: 2,
    titleItem: "Registro de Candidatos",
    flushID: "RegistroCandidatos",
    children: [
      {
        id: 1,
        titleItem: "Tablas de Partidos Políticos e Independientes",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/2.pdf",
      },
      {
        id: 2,
        titleItem: "Tablas de Partidos Políticos(Suplencias)",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/3.pdf",
      },
      {
        id: 3,
        titleItem: "Suplencias Partidos Políticos 07-Junio-2018",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/4.pdf",
      },
      {
        id: 4,
        titleItem: "Suplencias Partidos Políticos",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/5.pdf",
      },
    ],
  }, */
 
  /* {
    id: 5,
    titleItem: "Financiamiento Público",
    flushID: "FinanciamientoPublico",
    children: [
      {
        id: 1,
        titleItem: "Financiamiento Público 2018",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/18.pdf",
      },
      {
        id: 2,
        titleItem: "Acuerdo del ITE",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/19.pdf",
      },
    ],
  },
  {
    id: 6,
    titleItem: "Topes de Gastos",
    flushID: "TopesGastos",
    children: [
      {
        id: 1,
        titleItem: "Precampaña 2018",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/20.pdf",
      },
      {
        id: 2,
        titleItem: "Campaña 2018",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/21.pdf",
      },
    ],
  },
  {
    id: 7,
    titleItem: "Acceso a Medios de Comunicación",
    flushID: "AccesoMediosComunicacion",
    children: [
      {
        id: 1,
        titleItem: "Acuerdo de Pautas de Radio y Televisión",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/22.pdf",
      },
      {
        id: 2,
        titleItem: "Acuerdo de Lineamientos de Radio y Televisión",
        typeDocument: "pdf",
        linkItem: "https://itetlax.org.mx/assets/pdf/partidosPoliticos/23.pdf",
      },
    ],
  }, */
];
